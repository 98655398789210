.accordion-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.accordion-title {
  background-color: #f7f7f7;
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  display: none;
  padding: 15px;
}

.accordion-content.active {
  display: block;
}

.product-item {
  display: flex;
  align-items: flex-start;
}

.img-container {
  flex: 1;
  margin-right: 20px;
}

.text-container {
  flex: 2;
}

.img-container img {
  max-width: 50%; /* Reduce the width to half */
  height: auto;
  object-fit: contain;
}

/* Specific styles for Reverse Osmosis items */
.reverse-osmosis .product-item {
  flex-direction: column;
  align-items: center;
}

.reverse-osmosis .img-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 0; /* Remove margin for reverse osmosis */
}

.reverse-osmosis .img-container .row {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.reverse-osmosis .text-container {
  text-align: center;
  margin-bottom: 20px;
}
